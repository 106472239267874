"use client";

import {
  useMutationPatchNotification,
  type GetNotificationsResponse,
} from "#app/_api/notification-service";
import { logError } from "#app/lib/logger";
import NextLink from "next/link";
import { isMaintenanceNotification } from "./my-updates.utils";
import { MyUpdatesDialogRead } from "./MyUpdatesDialogRead";

export function MyUpdatesButtonRead({
  children,
  className,
  data,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  data: Required<
    Pick<
      Awaited<GetNotificationsResponse>[number],
      | "created"
      | "icon"
      | "longMessage"
      | "shortMessage"
      | "typeId"
      | "updateId"
      | "webRoute"
    >
  >;
  href?: string;
  onClick?: () => void;
}) {
  const mutationPatchNotification = useMutationPatchNotification();
  const handleClick = () => {
    mutationPatchNotification.mutate({
      isRead: true,
      typeId: data.typeId,
      updateId: data.updateId,
    });
    onClick?.();
  };

  if (isMaintenanceNotification(data.typeId)) {
    return (
      <MyUpdatesDialogRead data={data}>
        <button
          type="button"
          onClick={() => handleClick()}
          className={className}
        >
          {children}
        </button>
      </MyUpdatesDialogRead>
    );
  }

  if (data.webRoute.startsWith("/")) {
    return (
      <NextLink
        href={data.webRoute}
        onClick={() => handleClick()}
        className={className}
      >
        {children}
      </NextLink>
    );
  }

  if (data.webRoute.startsWith("http")) {
    return (
      <a
        href={data.webRoute}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleClick()}
        className={className}
      >
        {children}
      </a>
    );
  }

  logError(`Invalid route: ${data.webRoute}`);
  return null;
}
