"use client";

import { Colors } from "#app/lib/Colors";
import { Card, CardContent, CardHeader } from "#ui/card";
import { Dot } from "#ui/dot";
import { H2 } from "#ui/typography";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import { useEffect, useRef } from "react";
import { PerformanceVm } from "./conversions";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

export interface PortfolioGraphParams {
  portfolioData: PerformanceVm | undefined;
  asOfLastBusDay: boolean;
  height?: number | null;
}

export function PortfolioGraph({
  portfolioData,
  asOfLastBusDay,
  height,
}: PortfolioGraphParams) {
  const chartComponent = useRef<any>(null);

  useEffect(() => {
    if (chartComponent.current) {
      if (portfolioData) {
        chartComponent.current.chart.hideLoading();
      } else {
        chartComponent.current.chart.showLoading();
      }
    }
  }, [portfolioData]);

  const series = portfolioData
    ? {
        data: [
          portfolioData.ytd?.rateOfReturn ?? 0,
          portfolioData.oneYear?.rateOfReturn ?? 0,
          portfolioData.threeYear?.rateOfReturn ?? 0,
          portfolioData.fiveYear?.rateOfReturn ?? 0,
          portfolioData.sinceStart?.rateOfReturn ?? 0,
        ],
        name: portfolioData.accountNumber,
        color: Colors.gain,
        zonesAxis: "y",
        zones: [{ value: 0, color: Colors.loss }],
        borderRadius: 3,
        pointWidth: 30,
      }
    : null;

  const plotBands = [...new Array(6)].map((_, i) => {
    return {
      color: "#CDD2D9",
      from: -0.1 + i,
      to: 0.1 + i,
    };
  });

  if (series && portfolioData && asOfLastBusDay) {
    series.data.splice(1, 0, portfolioData.mtd?.rateOfReturn ?? 0);
  }

  var categories = ["YTD", "1Y", "3Y", "5Y", "All"];
  if (asOfLastBusDay) {
    categories.splice(1, 0, "MTD");
  }

  const series2 = { ...series, borderRadius: 2 };

  function getLongString(shortString: string) {
    switch (shortString) {
      case "YTD":
        return "Year To Date";
      case "MTD":
        return "Month To Date";
      case "1Y":
        return "1 Year";
      case "3Y":
        return "3 Year";
      case "5Y":
        return "5 Year";
      default:
        return shortString;
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        ref={chartComponent}
        options={{
          chart: {
            type: "column",
            height,
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: "",
          },
          xAxis: {
            categories: categories,
            labels: {},
            plotBands: plotBands,
          },
          yAxis: {
            title: "",
            labels: {
              formatter: (value: any): string => {
                return Highcharts.numberFormat(value.value, 0) + "%";
              },
              style: {
                fontSize: "0.75em",
                color: "#222222",
              },
            },
          },
          series: [series2],
          loading: {
            labelStyle: {
              color: "white",
            },
            style: {
              backgroundColor: "gray",
            },
          },
          legend: { enabled: false },
          tooltip: {
            x: "",
            y: "",
            formatter: function (): any {
              const ttTitle = getLongString(this.x);
              const percentage = +this.y > 0 ? "+" + this.y : this.y;
              const color = +this.y >= 0 ? "#669933" : "#983222";
              return (
                '<div style="width:127px;height:72px;"><b>' +
                ttTitle +
                '</b><br/><span style="color:' +
                color +
                '">' +
                percentage +
                "</span></div>"
              );
            },
          },
          credits: {
            enabled: false,
          },
        }}
      />
      <div aria-label="Legend" className="font-sm space-x-20px pl-17px">
        <span className="inline-flex items-center gap-5px">
          <Dot
            size="md"
            palette="gain"
            aria-hidden="false"
            aria-label="green"
          />
          <span>Gain</span>
        </span>{" "}
        <span className="inline-flex items-center gap-5px">
          <Dot size="md" palette="loss" aria-hidden="false" aria-label="red" />
          <span>Loss</span>
        </span>
      </div>
    </>
  );
}

export default function PortfolioGraphCard({
  portfolioData,
  asOfLastBusDay,
}: PortfolioGraphParams) {
  return (
    <>
      <Card variant="white-shadow" className="lg:col-span-8">
        <CardHeader>
          <H2 size="h6">Portfolio Performance</H2>
        </CardHeader>
        <CardContent className="pt-25px">
          <PortfolioGraph
            asOfLastBusDay={asOfLastBusDay}
            portfolioData={portfolioData}
          />
        </CardContent>
      </Card>
    </>
  );
}
