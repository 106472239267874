import { MfaChallengeType } from "#app/_api/authentication-service";

export function cacheMfaSession(mfaSession: string) {
    sessionStorage.setItem('mfaSession', mfaSession);
}

export function removeMfaSession() {
    sessionStorage.removeItem('mfaSession');
}

export function getMfaSession(): string | null {
    return sessionStorage.getItem('mfaSession');
}


export function cacheMfaTypes(mfaTypes: MfaChallengeType[]) {
    sessionStorage.setItem('mfaTypes', JSON.stringify(mfaTypes));
}

export function removeMfaTypes() {
    sessionStorage.removeItem('mfaTypes');
}

export function getMfaTypes(): MfaChallengeType[] | null {
    const item = sessionStorage.getItem('mfaTypes');
    const parsedItem = JSON.parse(item ?? "");
    return item !== null && Array.isArray(parsedItem) ? parsedItem as MfaChallengeType[] : null;
}