import { Segment } from "#app/(unauthorized)/authentication/segment";
import { type NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import { type NavRoutesConfigFilterParams } from "./filterNav";

export default function notLoggedInNavFilter({
  jwt,
  navRoutesConfig,
  requiredData: _requiredData,
}: NavRoutesConfigFilterParams): NavRoutesConfig {
  if (jwt.segment == Segment.NotLoggedIn) {
    return {};
  }

  return navRoutesConfig;
}
