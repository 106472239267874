import { Icon, type IconProps } from "#ui/icon";
import { cx } from "#ui/style.utils";

const myUpdatesIconPropsMap = {
  "achtransaction.png": {
    className: cx("bg-shade-40 text-error-100"),
    name: "baird-transfer",
  },
  "checkmark.png": {
    className: cx("bg-secondary-120 text-white"),
    name: "baird-email-phone-change",
  },
  "email.png": {
    className: cx("bg-success-120 text-white"),
    name: "baird-provide-email",
  },
  "financial-plan.png": {
    className: cx("bg-error-80 text-white"),
    name: "baird-financial-plan",
  },
  "maintenance.png": {
    className: cx("bg-warning-80 text-white"),
    name: "baird-maintenance",
  },
  "market-strategy-weekly.png": {
    className: cx("bg-chart-purple-100 text-white"),
    name: "baird-marketing-strategy",
  },
  "marketing-article.png": {
    className: cx("bg-info-60 text-white"),
    name: "baird-marketing-article",
  },
  "mobiledeposit.png": {
    className: cx("bg-warning-80 text-error-100"),
    name: "baird-paperless",
  },
  "paperless.png": {
    className: cx("bg-primary-100 text-white"),
    name: "baird-paperless",
  },
  "phone.png": {
    className: cx("bg-neutral-100 text-white"),
    name: "baird-provide-phone",
  },
  "secure-doc.png": {
    className: cx("bg-black text-white"),
    name: "baird-secure-document",
  },
  "wealth-strategy-webinar.png": {
    className: cx("bg-warning-120 text-white"),
    name: "baird-wealth-strategy-webinar",
  },
  unknown: {
    className: cx("bg-shade-4 text-transparent"),
    name: "custom-not-applicable",
  },
} as const satisfies Record<
  string,
  { className: string; name: IconProps["name"] }
>;

export function MyUpdatesIcon({
  className,
  icon,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & {
  icon: string;
}) {
  const myUpdatesIconProps =
    myUpdatesIconPropsMap[icon as keyof typeof myUpdatesIconPropsMap] ??
    myUpdatesIconPropsMap.unknown;
  return (
    <div
      {...props}
      className={cx(
        "grid size-40px shrink-0 place-content-center rounded-md",
        myUpdatesIconProps.className,
        className,
      )}
    >
      <Icon name={myUpdatesIconProps.name} size="baird-md" />
    </div>
  );
}
