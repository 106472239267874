"use client";

import { MyUpdatesButtonDelete } from "#app/(secured)/my-updates/_shared/MyUpdatesButtonDelete";
import { MyUpdatesButtonRead } from "#app/(secured)/my-updates/_shared/MyUpdatesButtonRead";
import { MyUpdatesDateTime } from "#app/(secured)/my-updates/_shared/MyUpdatesDateTime";
import { MyUpdatesDialogDelete } from "#app/(secured)/my-updates/_shared/MyUpdatesDialogDelete";
import { MyUpdatesEmpty } from "#app/(secured)/my-updates/_shared/MyUpdatesEmpty";
import { MyUpdatesIcon } from "#app/(secured)/my-updates/_shared/MyUpdatesIcon";
import { MyUpdatesIndicator } from "#app/(secured)/my-updates/_shared/MyUpdatesIndicator";
import { MyUpdatesTitle } from "#app/(secured)/my-updates/_shared/MyUpdatesTitle";
import {
  isMaintenanceNotification,
  isRouteablePath,
} from "#app/(secured)/my-updates/_shared/my-updates.utils";
import {
  useQueryNotifications,
  type GetNotificationsResponse,
} from "#app/_api/notification-service";
import { logError } from "#app/lib/logger";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  type CardRootProps,
} from "#ui/card";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { cx } from "#ui/style.utils";
import { H2 } from "#ui/typography";
import { ErrorCardContent } from "../cards/ErrorCard";
import { PendingCardContent } from "../cards/PendingCard";

export function CardMyUpdates(props: CardRootProps) {
  const { data, error, isError, isPending, isSuccess } =
    useQueryNotifications();

  if (isError) {
    logError(error);
  }

  const isFound =
    isSuccess &&
    data.length > 0 &&
    data.some(
      (el) =>
        isMaintenanceNotification(el.typeId) || isRouteablePath(el.webRoute),
    );
  const isEmpty = isSuccess && !isFound;

  return (
    <Card {...props}>
      <CardHeader palette="gray">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-pie-chart" size="baird-md" />
          <span>My Updates</span>
        </H2>
      </CardHeader>
      <CardContent
        className={
          isEmpty ? "pb-50px pt-[42px] sm:pt-32px" : isFound ? "p-0" : undefined
        }
      >
        {isPending ? <PendingCardContent className="md:min-h-400px" /> : null}
        {isError ? <ErrorCardContent className="md:min-h-400px" /> : null}
        {isEmpty ? <MyUpdatesEmpty /> : null}
        {isFound ? <MyUpdatesFound data={data.slice(0, 4)} /> : null}
      </CardContent>
      {isFound ? (
        <CardFooter palette="white">
          <LinkButton href="/my-updates">See all updates</LinkButton>
        </CardFooter>
      ) : null}
    </Card>
  );
}

function MyUpdatesFound({
  className,
  data,
  ...props
}: React.ComponentPropsWithoutRef<"ul"> & {
  data: Awaited<GetNotificationsResponse>;
}) {
  return (
    <ul {...props} className={cx("divide-y divide-shade-10", className)}>
      {data.map((el) => (
        <li
          className="flex flex-nowrap items-center gap-15px px-30px py-20px"
          key={el.updateId}
        >
          <MyUpdatesButtonRead
            data={el}
            className="flex flex-1 flex-nowrap items-center gap-15px text-left"
          >
            <MyUpdatesIndicator
              isRead={el.isRead}
              className="-ml-10px -mr-5px"
            />
            <MyUpdatesIcon icon={el.icon} />
            <div className="flex-1">
              <MyUpdatesTitle>{el.shortMessage}</MyUpdatesTitle>
              <MyUpdatesDateTime value={el.created} />
            </div>
          </MyUpdatesButtonRead>
          {el.readonly ? null : (
            <MyUpdatesDialogDelete data={el}>
              <MyUpdatesButtonDelete size="sm" />
            </MyUpdatesDialogDelete>
          )}
        </li>
      ))}
    </ul>
  );
}
