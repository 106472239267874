import { Dot, type DotProps } from "#ui/dot";

export function MyUpdatesIndicator({
  isRead,
  ...props
}: Omit<DotProps, "aria-hidden" | "aria-label" | "palette" | "size"> & {
  isRead: boolean;
}) {
  return (
    <Dot
      {...props}
      aria-hidden="false"
      aria-label={isRead ? "(Read)" : "(Unread)"}
      palette={isRead ? "transparent" : "secondary"}
      size="sm"
    />
  );
}
