import { P } from "#ui/typography";
import Image from "next/image";

export function MyUpdatesEmpty() {
  return (
    <div className="space-y-30px text-center">
      <Image
        alt=""
        src="/Images/image-empty-state.png"
        priority
        width="211"
        height="171"
        className="mx-auto max-sm:w-[148px]"
      />
      <P size="h6">You have no updates right now.</P>
    </div>
  );
}
