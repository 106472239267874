"use client";

import { useUserEligibility } from "#app/_api/fund-transfer-service-user";
import { useHeldawayUserEligibilityQueryOptions } from "#app/_api/heldaway-service";
import { type CardRootProps, Card, CardContent, CardHeader } from "#ui/card";
import { Icon, IconProps } from "#ui/icon";
import { H2 } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import Link, { LinkProps } from "next/link";

export function CardQuickActions(props: CardRootProps) {
  const externalFundsEligibility = useQuery(useUserEligibility());
  const heldawayEligibility = useQuery(
    useHeldawayUserEligibilityQueryOptions(),
  );

  const hasLinkAccountPermissions = heldawayEligibility?.data === true;
  const hasTransferPermissionsTypes = ["Allow", "Market"];
  const hasTransferPermissions =
    hasTransferPermissionsTypes.includes(
      externalFundsEligibility?.data?.ach ?? "Hide",
    ) ||
    hasTransferPermissionsTypes.includes(
      externalFundsEligibility?.data?.onlineTransferRequest ?? "Hide",
    );

  return (
    <Card {...props} variant="blue-gradient">
      <CardHeader>
        <H2 size="h6" className="leading-none">
          <span>Quick Actions</span>
        </H2>
      </CardHeader>
      <CardContent>
        <LinkGroup>
          {hasLinkAccountPermissions ? (
            <LinkGroupItem href="/360-wealth">
              <LinkGroupIcon name="ms-link" />
              <div>Link External Account</div>
            </LinkGroupItem>
          ) : null}
          {hasTransferPermissions ? (
            <LinkGroupItem href="/account-services/transfers/transfer-form">
              <LinkGroupIcon name="custom-transfer" />
              <div>Make a Transfer</div>
            </LinkGroupItem>
          ) : null}
          <LinkGroupItem href="/documents">
            <LinkGroupIcon name="ms-file" />
            <div>View Documents</div>
          </LinkGroupItem>
          <LinkGroupItem href="/documents/sent">
            <LinkGroupIcon name="ms-upload-filled" />
            <div>Upload Document</div>
          </LinkGroupItem>
        </LinkGroup>
      </CardContent>
    </Card>
  );
}

function LinkGroup({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex justify-between gap-5px text-center text-sm">
      {children}
    </div>
  );
}

function LinkGroupItem({
  children,
  ...props
}: { children: React.ReactNode } & LinkProps) {
  return (
    <Link
      {...props}
      className="grid max-w-80px shrink-0 justify-items-center gap-10px"
    >
      {children}
    </Link>
  );
}

function LinkGroupIcon({ name }: { name: IconProps["name"] }) {
  return (
    <div className="grid size-60px place-content-center rounded-full bg-black/20">
      <Icon name={name} size="lg" />
    </div>
  );
}
