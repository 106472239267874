import { getCurrentJwt, refreshCurrentJwt } from '#app/(unauthorized)/authentication/jwt';
import { cacheJwt } from '#app/(unauthorized)/authentication/jwtStorageService';
import * as authenticationService from "#app/_api/authentication-service";
import { ActivityTimerFailed, createActivityTimer, startTimer } from '@/lib/activityTimer';
import { logTrace } from '@/lib/logger';
import { Segment } from "./segment";

const activityTimerName = 'JWT';
export function initJwtRefreshTimer(failureAction: () => void) {
    createActivityTimer({
        name: activityTimerName,
        getDelay: _ => {
            const currentJwt = getCurrentJwt();
            return currentJwt.getJwtDelay() 
        },
        onTrigger: async () => {
            const newToken  = await authenticationService.token();
            cacheJwt(newToken.jwt);
            refreshCurrentJwt();
            return true;
        },
        failed: async (_, failure?: ActivityTimerFailed) => {
            const currentJwt = getCurrentJwt();
            if ((failure?.error?.message === 'JWT Expired') || currentJwt.getJwtDelay().totalMilliseconds <= 0) {
                failureAction();
            }
        },
        stopped: () => { }
    });
}

export function startJwtRefreshTimer() {
    const currentJwt = getCurrentJwt();  
    if (currentJwt.segment != Segment.NotLoggedIn) {
        logTrace('Staring Jwt Refresh Timer');
        startTimer(activityTimerName, { });
    }
}