"use client";

import {
  Action as RadixAlertDialogAction,
  Cancel as RadixAlertDialogCancel,
  Content as RadixAlertDialogContent,
  Description as RadixAlertDialogDescription,
  Overlay as RadixAlertDialogOverlay,
  Portal as RadixAlertDialogPortal,
  Root as RadixAlertDialogRoot,
  Title as RadixAlertDialogTitle,
  Trigger as RadixAlertDialogTrigger,
  type AlertDialogActionProps as RadixAlertDialogActionProps,
  type AlertDialogCancelProps as RadixAlertDialogCancelProps,
  type AlertDialogContentProps as RadixAlertDialogContentProps,
  type AlertDialogDescriptionProps as RadixAlertDialogDescriptionProps,
  type AlertDialogOverlayProps as RadixAlertDialogOverlayProps,
  type AlertDialogPortalProps as RadixAlertDialogPortalProps,
  type AlertDialogProps as RadixAlertDialogRootProps,
  type AlertDialogTitleProps as RadixAlertDialogTitleProps,
  type AlertDialogTriggerProps as RadixAlertDialogTriggerProps,
} from "@radix-ui/react-alert-dialog";
import { forwardRef } from "react";
import {
  alertDialogCloseIconButtonVariants,
  alertDialogContentVariants,
  alertDialogDescriptionVariants,
  alertDialogFooterVariants,
  alertDialogHeaderVariants,
  alertDialogMainVariants,
  alertDialogOverlayVariants,
  alertDialogTitleVariants,
  type AlertDialogCloseIconButtonVariants,
  type AlertDialogContentVariants,
  type AlertDialogDescriptionVariants,
  type AlertDialogFooterVariants,
  type AlertDialogHeaderVariants,
  type AlertDialogMainVariants,
  type AlertDialogOverlayVariants,
  type AlertDialogTitleVariants,
} from "./alert-dialog.styles";
import { Icon } from "./icon";

// -----------------------------------------------------------------------------

export type AlertDialogRef = React.ElementRef<typeof RadixAlertDialogRoot>;

export interface AlertDialogProps extends RadixAlertDialogRootProps {}

export const AlertDialog = RadixAlertDialogRoot;

// -----------------------------------------------------------------------------

export type AlertDialogTriggerRef = React.ElementRef<
  typeof RadixAlertDialogTrigger
>;

export interface AlertDialogTriggerProps extends RadixAlertDialogTriggerProps {}

export const AlertDialogTrigger = RadixAlertDialogTrigger;

// -----------------------------------------------------------------------------

export type AlertDialogPortalRef = React.ElementRef<
  typeof RadixAlertDialogPortal
>;

export interface AlertDialogPortalProps extends RadixAlertDialogPortalProps {}

export const AlertDialogPortal = RadixAlertDialogPortal;

// -----------------------------------------------------------------------------

export type AlertDialogCancelRef = React.ElementRef<
  typeof RadixAlertDialogCancel
>;

export interface AlertDialogCancelProps extends RadixAlertDialogCancelProps {}

export const AlertDialogCancel = RadixAlertDialogCancel;

// -----------------------------------------------------------------------------

export type AlertDialogActionRef = React.ElementRef<
  typeof RadixAlertDialogAction
>;

export interface AlertDialogActionProps extends RadixAlertDialogActionProps {}

export const AlertDialogAction = RadixAlertDialogAction;

// -----------------------------------------------------------------------------

export type AlertDialogOverlayRef = React.ElementRef<
  typeof RadixAlertDialogOverlay
>;

export interface AlertDialogOverlayProps
  extends RadixAlertDialogOverlayProps,
    AlertDialogOverlayVariants {}

const AlertDialogOverlay = forwardRef<
  AlertDialogOverlayRef,
  AlertDialogOverlayProps
>(({ className, ...props }, ref) => (
  <RadixAlertDialogOverlay
    {...props}
    className={alertDialogOverlayVariants({ className })}
    ref={ref}
  />
));

AlertDialogOverlay.displayName = RadixAlertDialogOverlay.displayName;

export { AlertDialogOverlay };

// -----------------------------------------------------------------------------

export type AlertDialogContentRef = React.ElementRef<
  typeof RadixAlertDialogContent
>;

export interface AlertDialogContentProps
  extends RadixAlertDialogContentProps,
    AlertDialogContentVariants {}

const AlertDialogContent = forwardRef<
  AlertDialogContentRef,
  AlertDialogContentProps
>(({ className, variant, ...props }, ref) => (
  <RadixAlertDialogContent
    {...props}
    data-variant={variant}
    className={alertDialogContentVariants({ className, variant })}
    ref={ref}
  />
));

AlertDialogContent.displayName = RadixAlertDialogContent.displayName;

export { AlertDialogContent };

// -----------------------------------------------------------------------------

export type AlertDialogHeaderRef = React.ElementRef<"div">;

export interface AlertDialogHeaderProps
  extends React.ComponentPropsWithoutRef<"div">,
    AlertDialogHeaderVariants {}

const AlertDialogHeader = forwardRef<
  AlertDialogHeaderRef,
  AlertDialogHeaderProps
>(({ className, ...props }, ref) => (
  <div
    {...props}
    className={alertDialogHeaderVariants({ className })}
    ref={ref}
  />
));

AlertDialogHeader.displayName = "AlertDialogHeader";

export { AlertDialogHeader };

// -----------------------------------------------------------------------------

export type AlertDialogMainRef = React.ElementRef<"div">;

export interface AlertDialogMainProps
  extends React.ComponentPropsWithoutRef<"div">,
    AlertDialogMainVariants {}

const AlertDialogMain = forwardRef<AlertDialogMainRef, AlertDialogMainProps>(
  ({ className, ...props }, ref) => (
    <div
      {...props}
      className={alertDialogMainVariants({ className })}
      ref={ref}
    />
  ),
);

AlertDialogMain.displayName = "AlertDialogMain";

export { AlertDialogMain };

// -----------------------------------------------------------------------------

export type AlertDialogFooterRef = React.ElementRef<"div">;

export interface AlertDialogFooterProps
  extends React.ComponentPropsWithoutRef<"div">,
    AlertDialogFooterVariants {}

const AlertDialogFooter = forwardRef<
  AlertDialogFooterRef,
  AlertDialogFooterProps
>(({ className, ...props }, ref) => (
  <div
    {...props}
    className={alertDialogFooterVariants({ className })}
    ref={ref}
  />
));

AlertDialogFooter.displayName = "AlertDialogFooter";

export { AlertDialogFooter };

// -----------------------------------------------------------------------------

export type AlertDialogTitleRef = React.ElementRef<
  typeof RadixAlertDialogTitle
>;

export interface AlertDialogTitleProps
  extends RadixAlertDialogTitleProps,
    AlertDialogTitleVariants {}

const AlertDialogTitle = forwardRef<AlertDialogTitleRef, AlertDialogTitleProps>(
  ({ className, ...props }, ref) => (
    <RadixAlertDialogTitle
      {...props}
      className={alertDialogTitleVariants({ className })}
      ref={ref}
    />
  ),
);

AlertDialogTitle.displayName = RadixAlertDialogTitle.displayName;

export { AlertDialogTitle };

// -----------------------------------------------------------------------------

export type AlertDialogDescriptionRef = React.ElementRef<
  typeof RadixAlertDialogDescription
>;

export interface AlertDialogDescriptionProps
  extends RadixAlertDialogDescriptionProps,
    AlertDialogDescriptionVariants {}

const AlertDialogDescription = forwardRef<
  AlertDialogDescriptionRef,
  AlertDialogDescriptionProps
>(({ className, ...props }, ref) => (
  <RadixAlertDialogDescription
    {...props}
    className={alertDialogDescriptionVariants({ className })}
    ref={ref}
  />
));

AlertDialogDescription.displayName = RadixAlertDialogDescription.displayName;

export { AlertDialogDescription };

// -----------------------------------------------------------------------------

export type AlertDialogCloseIconButtonRef = React.ElementRef<"button">;

export interface AlertDialogCloseIconButtonProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children">,
    AlertDialogCloseIconButtonVariants {}

const AlertDialogCloseIconButton = forwardRef<
  AlertDialogCloseIconButtonRef,
  AlertDialogCloseIconButtonProps
>(({ className, ...props }, ref) => (
  <button
    {...props}
    type="button"
    className={alertDialogCloseIconButtonVariants({ className })}
    ref={ref}
  >
    <Icon name="ms-close" />
    <span className="sr-only">Close</span>
  </button>
));

AlertDialogCloseIconButton.displayName = "AlertDialogCloseIconButton";

export { AlertDialogCloseIconButton };
