
class Env {
    get gatewayBaseUrl(): string {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_GATEWAY_BASE_URL || '';
    }
    get jwtTimerRefreshDelta(): number {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_JWT_TIMER_REFRESH_DELTA ? parseInt(process.env.NEXT_PUBLIC_JWT_TIMER_REFRESH_DELTA) : -1;
    }
    get autoLogOffIdleTime(): number {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_AUTO_LOG_OFF_IDLE_TIME ? parseInt(process.env.NEXT_PUBLIC_AUTO_LOG_OFF_IDLE_TIME) : -1;
    }
    get autoLogOffCountdownTime(): number {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_AUTO_LOG_OFF_COUNTDOWN_TIME ? parseInt(process.env.NEXT_PUBLIC_AUTO_LOG_OFF_COUNTDOWN_TIME) : 30;
    }
    get dynatraceScript(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_DYNATRACE_SCRIPT || null;
    }
    get recaptchaSiteKey(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || null;
    }
    get yodleeFastLinkUrl(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_YODLEE_FASTLINK_URL || null;
    }
    get yodleeIFrameUrl(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_YODLEE_IFRAME_URL || null;
    }

    get icIframeUrl(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_IC_IFRAME_URL || null;
    }
    get icUrl(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_IC_URL || null;
    }
    get wealthAccessUrl(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_WEALTH_ACCESS_URL || null;
    }
    get digitalOnboardingUrl(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_DIGITAL_ONBOARDING_URL || null;
    }
    get websiteDomain(): string | null {
        // @ts-expect-error nextjs requires this syntax
        return process.env.NEXT_PUBLIC_WEBSITE_DOMAIN || null;
    }
}

const env = new Env();
export default env;