import { Button, type ButtonProps, type ButtonRef } from "#ui/button";
import { Icon } from "#ui/icon";
import { forwardRef } from "react";

const MyUpdatesButtonDelete = forwardRef<
  ButtonRef,
  Omit<ButtonProps, "children" | "iconOnly" | "onClick" | "palette" | "variant">
>((props, ref) => (
  <Button {...props} variant="outline" palette="error" iconOnly ref={ref}>
    <Icon name="ms-close" />
  </Button>
));

MyUpdatesButtonDelete.displayName = "MyUpdatesButtonDelete";

export { MyUpdatesButtonDelete };
