export interface MoneyProps {
  value: number | null | undefined;
}

export function Money({ value }: MoneyProps) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (value == null || value == undefined) return "";

  return formatter.format(value);
}

export function formatCurrency(value: unknown) {
  const IntlNumberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    trailingZeroDisplay: "stripIfInteger",
  });

  if (typeof value === "number") {
    return IntlNumberFormat.format(value);
  }

  return "";
}
