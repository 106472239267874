"use client";
import { usePortfolioQueryOptions } from "#app/_api/portfolio-service";
import { PendingCardContent } from "#app/_ui/components/cards/PendingCard";
import { Money } from "#app/_ui/components/textFormaters/Money";
import { format, isValid, parseISO } from "#node_modules/date-fns";
import { GainLoss } from "#ui/gain-loss";
import { useQuery } from "@tanstack/react-query";

function KpiContentSecondary({children}:{children: React.ReactNode}){
  return <div className="text-sm leading-none  text-white/40">{children}</div>;
}

function KpiContentPrimary({children}:{children: React.ReactNode}){
  return <div className="text-h2 leading-none text-white">{children}</div>;
}

function Kpi({children} : {children: React.ReactNode}){
  return <div className="flex flex-col gap-10px">{children}</div>;
}

export default function DashboardKpis(){
  const {data: portfolio, isPending, isError} = useQuery(usePortfolioQueryOptions());

  if(isPending){ return <PendingCardContent/>;}

  const portfolioValue = isError? 'N/A' : <Money value={portfolio!.totals?.value} />;

  const asOfDate = parseISO(portfolio?.totals?.asOfDate || "")
  const asOf =
    isError || !isValid(asOfDate)
      ? "N/A"
      : format(asOfDate, "M/d/yyyy");


  const sumOfDailyChange = isError? 'N/A' : <GainLoss value={portfolio!.totals?.change} format="currency" className="-mr-6px flex-row-reverse text-white" />;
  const percentageChange = isError? 'N/A' : <>{(portfolio!.totals?.percentChange ?? 0) > 0 ? "+ " : ""}
  {portfolio!.totals?.percentChange}%</>;

const cashEquivalents = isError? 'N/A' : <Money value={portfolio!.totals?.balances?.cashValue} />;

return <div className="grid gap-30px md:grid-cols-3">
<Kpi>
  <KpiContentSecondary>Portfolio Value</KpiContentSecondary>
  <KpiContentPrimary>{portfolioValue}</KpiContentPrimary>
  <KpiContentSecondary>As Of {asOf}</KpiContentSecondary>
</Kpi>

<Kpi>
  <KpiContentSecondary>Sum of Daily Change</KpiContentSecondary>
  <KpiContentPrimary>
    <div className="flex items-center">{sumOfDailyChange}</div>
  </KpiContentPrimary>
  <KpiContentSecondary>
    {percentageChange}
  </KpiContentSecondary>
</Kpi>

<Kpi>
  <KpiContentSecondary>Cash/Cash Equivalents</KpiContentSecondary>
  <KpiContentPrimary>{cashEquivalents}</KpiContentPrimary>
</Kpi>
</div>;
}