import {
  Card,
  CardContent,
  CardContentProps,
  type CardRootProps,
} from "#ui/card";
import { cx } from "#ui/style.utils";
import { H2, type H2Props } from "#ui/typography";
import Image from "next/image";

interface NoResultsCardContentProps extends Omit<CardRootProps, "title"> {
  title: H2Props["children"];
  children?: CardContentProps["children"];
  size?: string
}

export function NoResultsCardContent({
  children,
  title,
  size,
  ...props
}: NoResultsCardContentProps) {
  return (
        <div {...props}
              className={cx(
                // Same classNames as PendingCardContent - Keep in sync!
                "grid place-content-center place-items-center gap-20px text-center md:min-h-280px max-md:py-30px",
              )}>
          <Image
            alt=""
            src="/Images/image-empty-state.png"
            priority
            width={size == "sm" ? "148": "288"}
            height={size == "sm" ? "120": "220"}
            className="md:mb-20px"
          />
          <H2 size="h5" className="leading-none">
            {title}
          </H2>
          {children}
        </div>
  );
}

interface NoResultsCardProps extends Omit<CardRootProps, "title"> {
  title: H2Props["children"];
  children?: CardContentProps["children"];
}

export function NoResultsCard({
  children,
  title,
  ...props
}: NoResultsCardProps) {
  return (
    <Card variant="white-border-and-blur" {...props}>
      <CardContent className="grid place-content-center md:min-h-320px">
        <div className="flex flex-col items-center gap-20px text-center md:py-30px">
          <Image
            alt=""
            src="/Images/image-empty-state.png"
            priority
            width="288"
            height="220"
            className="md:mb-20px"
          />
          <H2 size="h5" className="leading-none">
            {title}
          </H2>
          {children}
        </div>
      </CardContent>
    </Card>
  );
}
