import { forwardRef } from "react";
import { dotVariants, type DotVariants } from "./dot.styles";
import { Icon } from "./icon";

export type DotRef = React.ElementRef<typeof Icon>;

export interface DotProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof Icon>,
      "name" | "palette" | "size"
    >,
    DotVariants {}

const Dot = forwardRef<DotRef, DotProps>(
  ({ className, palette, size, ...props }, ref) => {
    return (
      <Icon
        {...props}
        name="custom-circle-filled"
        className={dotVariants({ className, palette, size })}
        ref={ref}
      />
    );
  },
);

Dot.displayName = "Dot";

export { Dot };
