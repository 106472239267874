import {
  Arrow as RadixSelectArrow,
  Content as RadixSelectContent,
  Group as RadixSelectGroup,
  Icon as RadixSelectIcon,
  Item as RadixSelectItem,
  ItemIndicator as RadixSelectItemIndicator,
  ItemText as RadixSelectItemText,
  Label as RadixSelectLabel,
  Portal as RadixSelectPortal,
  Root as RadixSelectRoot,
  ScrollDownButton as RadixSelectScrollDownButton,
  ScrollUpButton as RadixSelectScrollUpButton,
  Separator as RadixSelectSeparator,
  Trigger as RadixSelectTrigger,
  Value as RadixSelectValue,
  Viewport as RadixSelectViewport,
  type SelectArrowProps as RadixSelectArrowProps,
  type SelectContentProps as RadixSelectContentProps,
  type SelectGroupProps as RadixSelectGroupProps,
  type SelectIconProps as RadixSelectIconProps,
  type SelectItemIndicatorProps as RadixSelectItemIndicatorProps,
  type SelectItemProps as RadixSelectItemProps,
  type SelectItemTextProps as RadixSelectItemTextProps,
  type SelectLabelProps as RadixSelectLabelProps,
  type SelectPortalProps as RadixSelectPortalProps,
  type SelectProps as RadixSelectRootProps,
  type SelectScrollDownButtonProps as RadixSelectScrollDownButtonProps,
  type SelectScrollUpButtonProps as RadixSelectScrollUpButtonProps,
  type SelectSeparatorProps as RadixSelectSeparatorProps,
  type SelectTriggerProps as RadixSelectTriggerProps,
  type SelectValueProps as RadixSelectValueProps,
  type SelectViewportProps as RadixSelectViewportProps,
} from "@radix-ui/react-select";
import { forwardRef } from "react";
import { Icon } from "./icon";
import { type IconVariants } from "./icon.styles";
import {
  selectContentVariants,
  selectIconVariants,
  selectItemIndicatorVariants,
  selectItemTextVariants,
  selectItemVariants,
  selectLabelVariants,
  selectScrollDownButtonVariants,
  selectScrollUpButtonVariants,
  selectSeparatorVariants,
  selectTriggerVariants,
  selectViewportVariants,
  type SelectContentVariants,
  type SelectIconVariants,
  type SelectItemIndicatorVariants,
  type SelectItemTextVariants,
  type SelectItemVariants,
  type SelectLabelVariants,
  type SelectScrollDownButtonVariants,
  type SelectScrollUpButtonVariants,
  type SelectSeparatorVariants,
  type SelectTriggerVariants,
  type SelectViewportVariants,
} from "./select.styles";
import { formatAriaInvalid } from "./style.utils";

// -----------------------------------------------------------------------------

export type SelectRootRef = React.ElementRef<typeof RadixSelectRoot>;

export interface SelectRootProps extends RadixSelectRootProps {}

export const SelectRoot = RadixSelectRoot;

// -----------------------------------------------------------------------------

export type SelectTriggerRef = React.ElementRef<typeof RadixSelectTrigger>;

export interface SelectTriggerProps
  extends RadixSelectTriggerProps,
    SelectTriggerVariants {
  "aria-invalid"?: boolean | "true" | "false" | undefined;
}

const SelectTrigger = forwardRef<SelectTriggerRef, SelectTriggerProps>(
  (
    { "aria-invalid": ariaInvalid, className, disabled, size, ...props },
    ref,
  ) => {
    return (
      <RadixSelectTrigger
        {...props}
        disabled={disabled}
        aria-invalid={ariaInvalid}
        className={selectTriggerVariants({
          className,
          disabled,
          invalid: formatAriaInvalid(ariaInvalid),
          size,
        })}
        ref={ref}
      />
    );
  },
);

SelectTrigger.displayName = "SelectTrigger";

export { SelectTrigger };

// -----------------------------------------------------------------------------

export type SelectValueRef = React.ElementRef<typeof RadixSelectValue>;

export interface SelectValueProps extends RadixSelectValueProps {}

export const SelectValue = RadixSelectValue;

// -----------------------------------------------------------------------------

export type SelectIconRef = React.ElementRef<typeof RadixSelectIcon>;

export interface SelectIconProps
  extends Omit<RadixSelectIconProps, "asChild" | "children">,
    Pick<IconVariants, "size">,
    Omit<SelectIconVariants, "size"> {
  rootSize: SelectTriggerProps["size"];
}

const SelectIcon = forwardRef<SelectIconRef, SelectIconProps>(
  ({ className, disabled, invalid, rootSize, size, ...props }, ref) => {
    return (
      <RadixSelectIcon
        {...props}
        className={selectIconVariants({
          className,
          disabled,
          invalid,
          size: rootSize,
        })}
        ref={ref}
      >
        <Icon name="ms-expand-more" size={size} />
      </RadixSelectIcon>
    );
  },
);

SelectIcon.displayName = "SelectIcon";

export { SelectIcon };

// -----------------------------------------------------------------------------

export type SelectPortalRef = React.ElementRef<typeof RadixSelectPortal>;

export interface SelectPortalProps extends RadixSelectPortalProps {}

export const SelectPortal = RadixSelectPortal;

// -----------------------------------------------------------------------------

export type SelectContentRef = React.ElementRef<typeof RadixSelectContent>;

export interface SelectContentProps
  extends RadixSelectContentProps,
    SelectContentVariants {}

const SelectContent = forwardRef<SelectContentRef, SelectContentProps>(
  ({ className, position, ...props }, ref) => {
    return (
      <RadixSelectContent
        {...props}
        position={position}
        className={selectContentVariants({ className, position })}
        ref={ref}
      />
    );
  },
);

SelectContent.displayName = "SelectContent";

export { SelectContent };

// -----------------------------------------------------------------------------

export type SelectViewportRef = React.ElementRef<typeof RadixSelectViewport>;

export interface SelectViewportProps
  extends RadixSelectViewportProps,
    SelectViewportVariants {}

const SelectViewport = forwardRef<SelectViewportRef, SelectViewportProps>(
  ({ className, position, ...props }, ref) => {
    return (
      <RadixSelectViewport
        {...props}
        className={selectViewportVariants({ className, position })}
        ref={ref}
      />
    );
  },
);

SelectViewport.displayName = "SelectViewport";

export { SelectViewport };

// -----------------------------------------------------------------------------

export type SelectScrollUpButtonRef = React.ElementRef<
  typeof RadixSelectScrollUpButton
>;

export interface SelectScrollUpButtonProps
  extends Omit<RadixSelectScrollUpButtonProps, "children">,
    SelectScrollUpButtonVariants {}

const SelectScrollUpButton = forwardRef<
  SelectScrollUpButtonRef,
  SelectScrollUpButtonProps
>(({ className, ...props }, ref) => {
  return (
    <RadixSelectScrollUpButton
      {...props}
      className={selectScrollUpButtonVariants({ className })}
      ref={ref}
    >
      <Icon name="ms-expand-less" size="sm" />
    </RadixSelectScrollUpButton>
  );
});

SelectScrollUpButton.displayName = "SelectScrollUpButton";

export { SelectScrollUpButton };

// -----------------------------------------------------------------------------

export type SelectScrollDownButtonRef = React.ElementRef<
  typeof RadixSelectScrollDownButton
>;

export interface SelectScrollDownButtonProps
  extends Omit<RadixSelectScrollDownButtonProps, "children">,
    SelectScrollDownButtonVariants {}

const SelectScrollDownButton = forwardRef<
  SelectScrollDownButtonRef,
  SelectScrollDownButtonProps
>(({ className, ...props }, ref) => {
  return (
    <RadixSelectScrollDownButton
      {...props}
      className={selectScrollDownButtonVariants({ className })}
      ref={ref}
    >
      <Icon name="ms-expand-more" size="sm" />
    </RadixSelectScrollDownButton>
  );
});

SelectScrollDownButton.displayName = "SelectScrollDownButton";

export { SelectScrollDownButton };

// -----------------------------------------------------------------------------

export type SelectArrowRef = React.ElementRef<typeof RadixSelectArrow>;

export interface SelectArrowProps extends RadixSelectArrowProps {}

export const SelectArrow = RadixSelectArrow;

// -----------------------------------------------------------------------------

export type SelectGroupRef = React.ElementRef<typeof RadixSelectGroup>;

export interface SelectGroupProps extends RadixSelectGroupProps {}

export const SelectGroup = RadixSelectGroup;

// -----------------------------------------------------------------------------

export type SelectLabelRef = React.ElementRef<typeof RadixSelectLabel>;

export interface SelectLabelProps
  extends RadixSelectLabelProps,
    SelectLabelVariants {}

const SelectLabel = forwardRef<SelectLabelRef, SelectLabelProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixSelectLabel
        {...props}
        className={selectLabelVariants({ className })}
        ref={ref}
      />
    );
  },
);

SelectLabel.displayName = "SelectLabel";

export { SelectLabel };

// -----------------------------------------------------------------------------

export type SelectSeparatorRef = React.ElementRef<typeof RadixSelectSeparator>;

export interface SelectSeparatorProps
  extends RadixSelectSeparatorProps,
    SelectSeparatorVariants {}

const SelectSeparator = forwardRef<SelectSeparatorRef, SelectSeparatorProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixSelectSeparator
        {...props}
        className={selectSeparatorVariants({ className })}
        ref={ref}
      />
    );
  },
);

SelectSeparator.displayName = "SelectSeparator";

export { SelectSeparator };

// -----------------------------------------------------------------------------

export type SelectItemRef = React.ElementRef<typeof RadixSelectItem>;

export interface SelectItemProps
  extends RadixSelectItemProps,
    SelectItemVariants {}

const SelectItem = forwardRef<SelectItemRef, SelectItemProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixSelectItem
        {...props}
        className={selectItemVariants({ className })}
        ref={ref}
      />
    );
  },
);

SelectItem.displayName = "SelectItem";

export { SelectItem };

// -----------------------------------------------------------------------------

export type SelectItemTextRef = React.ElementRef<typeof RadixSelectItemText>;

export interface SelectItemTextProps
  extends Omit<RadixSelectItemTextProps, "asChild">,
    SelectItemTextVariants {}

const SelectItemText = forwardRef<SelectItemTextRef, SelectItemTextProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixSelectItemText asChild ref={ref}>
        <span {...props} className={selectItemTextVariants({ className })} />
      </RadixSelectItemText>
    );
  },
);

SelectItemText.displayName = "SelectItemText";

export { SelectItemText };

// -----------------------------------------------------------------------------

export type SelectItemIndicatorRef = React.ElementRef<
  typeof RadixSelectItemIndicator
>;

export interface SelectItemIndicatorProps
  extends Omit<RadixSelectItemIndicatorProps, "children">,
    SelectItemIndicatorVariants {}

const SelectItemIndicator = forwardRef<
  SelectItemIndicatorRef,
  SelectItemIndicatorProps
>(({ className, ...props }, ref) => {
  return (
    <RadixSelectItemIndicator
      {...props}
      className={selectItemIndicatorVariants({ className })}
      ref={ref}
    >
      <Icon name="ms-check" />
    </RadixSelectItemIndicator>
  );
});

SelectItemIndicator.displayName = "SelectItemIndicator";

export { SelectItemIndicator };
