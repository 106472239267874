import { cva, type VariantProps } from "./style.utils";

export type IconVariants = VariantProps<typeof iconVariants>;

export const iconVariants = cva({
  base: "shrink-0",
  variants: {
    inline: {
      true: "inline",
      false: "",
    },
    palette: {
      unset: null,
      accent: "text-link-idle",
      error: "text-error-100",
      warning: "text-warning-100",
      success: "text-success-100",
      info: "text-info-100",
      neutral: "text-shade-100",
      white: "text-white",
    },
    size: {
      sm: "size-14px",
      md: "size-18px",
      lg: "size-24px",
      "baird-sm": "size-22px",
      "baird-md": "size-28px",
      "baird-lg": "size-72px",
    },
  },
  defaultVariants: {
    inline: false,
    palette: "unset",
    size: "md",
  },
});
