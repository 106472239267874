import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type SelectTriggerVariants = VariantProps<typeof selectTriggerVariants>;

export const selectTriggerVariants = cva({
  base: [
    // layout
    "inline-flex w-full min-w-120px items-center justify-between",
    // radii/shadow
    "rounded-md",
    // border
    "border border-shade-10",
    // background
    "bg-white",
    // foreground
    "text-left text-shade-100",
    // foreground mobile/zoom fix is unnecessary with buttons,
    // but adding for consistency with other controls
    "sm:text-sm",
    // children SelectValue layout
    "first:*:min-w-0 first:*:flex-1 first:*:truncate",
    // focus outline/border
    "focus:outline-1 focus:outline-offset-0",
    "focus:border-ring focus:outline-ring",
  ],
  variants: {
    size: {
      sm: "h-30px pl-10px", // compact
      md: "h-36px pl-12px", // convenient
      lg: "h-40px pl-14px", // cozy
    },
    invalid: {
      true: "",
      false: "",
    },
    disabled: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      invalid: true,
      disabled: false,
      className: [
        // invalid ring
        "ring-1 ring-error-100",
        // invalid border
        "border-error-100",
        // invalid background
        "bg-error-20",
        // invalid focus outline/border
        "focus:border-ring focus:outline-ring",
      ],
    },
    {
      disabled: true,
      className: [
        // disabled
        "cursor-not-allowed",
        // disabled border
        "border-shade-10",
        // disabled background
        "bg-shade-2",
        // disabled foreground
        "text-shade-50",
      ],
    },
  ],
  defaultVariants: {
    size: "md",
    invalid: false,
    disabled: false,
  },
});

// -----------------------------------------------------------------------------

export type SelectIconVariants = VariantProps<typeof selectIconVariants>;

export const selectIconVariants = cva({
  base: "flex items-center justify-center",
  variants: {
    size: {
      sm: "size-30px", // compact
      md: "size-36px", // convenient
      lg: "size-40px", // cozy
    },
    invalid: {
      true: "",
      false: "",
    },
    disabled: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      invalid: true,
      disabled: false,
      className: "text-error-120",
    },
  ],
  defaultVariants: {
    size: "md",
    invalid: false,
    disabled: false,
  },
});

// -----------------------------------------------------------------------------

export type SelectContentVariants = VariantProps<typeof selectContentVariants>;

export const selectContentVariants = cva({
  base: [
    // layout
    "max-h-320px min-w-[var(--radix-select-trigger-width)] max-w-320px overflow-hidden",
    // positioning
    "relative z-50",
    // radii/shadow
    "rounded-md shadow-md",
    // border
    "border border-shade-10",
    // background
    "bg-white",
    // animation in
    "data-[state=open]:animate-in",
    "data-[state=open]:fade-in-0",
    "data-[state=open]:zoom-in-95",
    // animation out
    "data-[state=closed]:animate-out",
    "data-[state=closed]:fade-out-0",
    "data-[state=closed]:zoom-out-95",
  ],
  variants: {
    position: {
      "item-aligned": [
        // animation slide-in
        "data-[side=top]:slide-in-from-bottom-2",
        "data-[side=bottom]:slide-in-from-top-2",
        "data-[side=left]:slide-in-from-right-2",
        "data-[side=right]:slide-in-from-left-2",
      ],
      popper: [
        // animation slide-in
        "data-[side=top]:-translate-y-4px",
        "data-[side=bottom]:translate-y-4px",
        "data-[side=left]:-translate-x-4px",
        "data-[side=right]:translate-x-4px",
      ],
    },
  },
  defaultVariants: {
    position: "item-aligned", // match radix
  },
});

// -----------------------------------------------------------------------------

export type SelectViewportVariants = VariantProps<
  typeof selectViewportVariants
>;

export const selectViewportVariants = cva({
  base: "p-4px",
  variants: {
    position: {
      "item-aligned": "",
      popper: "",
    },
  },
  defaultVariants: {
    position: "item-aligned", // match radix
  },
});

// -----------------------------------------------------------------------------

export type SelectScrollUpButtonVariants = VariantProps<
  typeof selectScrollUpButtonVariants
>;

export const selectScrollUpButtonVariants = cva({
  base: "flex cursor-default items-center justify-center py-4px",
});

// -----------------------------------------------------------------------------

export type SelectScrollDownButtonVariants = VariantProps<
  typeof selectScrollDownButtonVariants
>;

export const selectScrollDownButtonVariants = cva({
  base: "flex cursor-default items-center justify-center py-4px",
});

// -----------------------------------------------------------------------------

export type SelectLabelVariants = VariantProps<typeof selectLabelVariants>;

export const selectLabelVariants = cva({
  base: [
    // layout
    "flex items-center px-8px py-6px",
    // foreground
    "text-sm font-semibold",
  ],
});

// -----------------------------------------------------------------------------

export type SelectSeparatorVariants = VariantProps<
  typeof selectSeparatorVariants
>;

export const selectSeparatorVariants = cva({
  base: [
    // layout
    "-mx-4px my-4px h-1px",
    // background
    "bg-shade-10",
  ],
});

// -----------------------------------------------------------------------------

export type SelectItemVariants = VariantProps<typeof selectItemVariants>;

export const selectItemVariants = cva({
  base: [
    // layout
    "flex w-full items-center justify-between py-6px pl-8px pr-32px",
    // positioning
    "relative",
    // radii
    "rounded",
    // foreground
    "cursor-default select-none text-sm",
    "text-shade-100",
    // focus unset outline
    "outline-none",
    // focus/hover/active
    "focus:bg-neutral-100/10",
    "hover:bg-neutral-100/10",
    "active:bg-neutral-100/10 data-[state=checked]:bg-neutral-100/10",
    // disabled
    "data-[disabled]:pointer-events-none data-[disabled]:text-shade-50/50",
  ],
});

// -----------------------------------------------------------------------------

export type SelectItemTextVariants = VariantProps<
  typeof selectItemTextVariants
>;

export const selectItemTextVariants = cva({
  base: [
    // layout
    "min-w-0 flex-1",
    // foreground
    "truncate",
  ],
});

// -----------------------------------------------------------------------------

export type SelectItemIndicatorVariants = VariantProps<
  typeof selectItemIndicatorVariants
>;

export const selectItemIndicatorVariants = cva({
  base: [
    // layout
    "flex size-32px items-center justify-center",
    // positioning
    "absolute right-0 top-1/2 -translate-y-1/2",
  ],
});
