import { format, parseISO } from "date-fns";

// Stablize constructor for performance
const IntlDateTimeFormat = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "shortGeneric",
});

/**
 * - Example input: 2024-12-31T20:03:14.932Z
 * - Example output: Dec 31, 2024 at 2:03 PM CT
 * @param value ISO Date string
 */
export function formatMyUpdatedDateTime(value: string) {
  const date = parseISO(value);
  const dateStr = format(date, "LLL d, yyyy");
  const timeStr = IntlDateTimeFormat.format(date);
  return `${dateStr} at ${timeStr}`;
}

export function isMaintenanceNotification(value: number) {
  return value === 2;
}

export function isRouteablePath(value: unknown) {
  return (
    typeof value === "string" &&
    (value.startsWith("/") || value.startsWith("http"))
  );
}
