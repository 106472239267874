"use client";
import { useRequiredData } from "#app/(required)/useRequiredData";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import * as heldawayService from "#app/_api/heldaway-service";
import { Alert, AlertContent } from "#ui/alert";
import {
  type CardRootProps,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "#ui/card";
import { GainLoss } from "#ui/gain-loss";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { H2, P } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import ErrorBanner from "../errors/error-banner";
import { Money } from "../textFormaters/Money";
import { heldawayQueryKeys } from "#app/_api/heldaway-service";

function NetWorthInfo({
  netWorth,
}: {
  netWorth: heldawayService.YodleeNetWorthDTO[];
}) {
  let currentValue: number | undefined;
  let firstValue: number | undefined;
  let ytd = 0;
  let ytdPercent = 0;
  if (netWorth !== undefined && netWorth.length > 0) {
    const sorted = netWorth.sort((x, y) => (x.date < y.date ? 1 : -1));
    const first = sorted[0];
    const last = sorted[sorted.length - 1];
    currentValue = last?.netWorth?.amount;
    firstValue = first?.netWorth?.amount;

    if (currentValue !== undefined && firstValue !== undefined) {
      ytd = currentValue - firstValue;
      ytdPercent = (ytd * 100) / currentValue;
    }
    else {
      ytd = currentValue! - firstValue!;
      ytdPercent = (ytd * 100) / currentValue!;
    }

  }

  return (
    <div className="flex flex-col gap-10px">
      <div className="text-sm leading-none text-shade-70">
        Today&apos;s Net Worth
      </div>
      <div className="text-h2 leading-none"><Money value={currentValue}/></div>
      <div className="flex items-center gap-5px">
        <GainLoss value={ytd} format="currency" />
        <span>
          {" "}
          (<GainLoss value={ytdPercent} format="percent" layout="textOnly" />)
        </span>{" "}
      </div>
    </div>
  );
}

export function CardMyNetWorth(props: CardRootProps) {

  const { requiredData } = useRequiredData();
  const { data: heldawayTsAndCs, error: heldawayError } = useQuery(
    heldawayService.heldawayQueryKeys.user,
  );
  const { data: registered, error: registerError } = heldawayService.useRegisterQuery();
  const { data: netWorth, error: netWorthError } = useQuery({... heldawayQueryKeys.netWorthYTD, enabled: registered?.registered ?? false});

  const { isImpersonated } = useJwt();
  const { data: restrictionData, error: restrictionError } = useQuery(
    heldawayService.useAssociateRestrictionQueryOptions(),
  );


  const { data: refreshAccountsData } = heldawayService.useRefreshAccountsWaitForRefresh();

  if (!requiredData.heldawayUserEligibility) {
    return null;
  }

  const isError =
    heldawayError || registerError || netWorthError || restrictionError;

  const isImpersonatedAndRestricted =
    isImpersonated && (restrictionData === undefined || restrictionData);

  const tsAndCsAccepted =
    heldawayTsAndCs === heldawayService.TermsStatus.Accepted;
    
  const isRegistered = registered?.registered ?? false;

  const acceptedAndRegistered = tsAndCsAccepted && (isRegistered === true);

  if(refreshAccountsData){} //workaround so react doesn't consider 'refreshAccountsData' to be useless ...

  return (
    <Card {...props}>
      <CardHeader palette="gray" className="flex items-center justify-between">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-360-wealth" size="baird-md" />
          <span>My Net Worth</span>
        </H2>
        <P size="sm" palette="neutral-subtle">
          Last updated as of {new Date().toLocaleDateString()}
        </P>
      </CardHeader>
      <CardContent>
        {isError ? (<ErrorBanner error="An error occurred." />) 
          : isImpersonatedAndRestricted ? ("The associate has elected to keep their 360 Wealth private.")
          : acceptedAndRegistered && (netWorth?.length ?? 0) > 0 ? (<NetWorthInfo netWorth={netWorth ?? []} />) 
          : (
          <Alert intent="info">
            <AlertContent>
              Find out your total net worth by linking your external accounts.{" "}
              <LinkButton href="/360-wealth" variant="link">
                Link an account
              </LinkButton>
            </AlertContent>
          </Alert>
        )}
      </CardContent>
      <CardFooter>
        <LinkButton className="w-full" href="/360-wealth">
          <span>See all</span>
        </LinkButton>
      </CardFooter>
    </Card>
  );
}