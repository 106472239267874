import { Segment } from "#app/(unauthorized)/authentication/segment";
import { type NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import filterHelper from "./filterHelper";
import { type NavRoutesConfigFilterParams } from "./filterNav";

export default function allowedSegmentFilter({
  jwt,
  navRoutesConfig,
  requiredData: _requiredData,
}: NavRoutesConfigFilterParams): NavRoutesConfig {
  var filtering = filterHelper(navRoutesConfig);
  const segment: Segment = parseInt(`${jwt.segment}`);

  for (var k in navRoutesConfig) {
    const routeConfig = navRoutesConfig[k];

    if (
      routeConfig!.allowList &&
      routeConfig!.allowList!.includes(segment) == false
    ) {
      filtering = filtering.removeRouteById(k);
      continue;
    }

    if (routeConfig!.paths) {
      for (var c in routeConfig!.paths) {
        const routeConfigSub = routeConfig!.paths![c];

        if (
          !routeConfigSub!.allowList ||
          routeConfigSub!.allowList!.length == 0
        )
          continue;

        if (routeConfigSub!.allowList!.includes(segment) == false) {
          filtering = filtering.removeRouteSubById(k, c);
        }
      }
    }
  }

  return filtering.getFilteredRoutesConfig();
}
