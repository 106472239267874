import { getJson, patchJson, postJson, remove } from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export type MandatoryActivityResult = {
  id: number;
  loginID: string;
  activityID: number;
};

export type NotificationResult = {
  updateId: string;
  typeId: number;
  readonly: boolean;
  route: string;
  webRoute: string;
  shortMessage: string | null;
  longMessage: string | null;
  icon: string;
  isRead: boolean;
  sortPriority: number | null;
  created: string;
  expiration: string;
  metaData: Record<string, string>;
};

export type DeleteNotificationRequest = {
  updateId: string;
  typeId: number;
};

export type UpdateNotificationRequest = {
  updateId: string;
  typeId: number;
  isRead: boolean;
};

const GATEWAY_PATH = "notifications/v1";

export type GetMandatoryActivityResponse = ReturnType<
  typeof getMandatoryActivity
>;

export async function getMandatoryActivity(): Promise<
  MandatoryActivityResult[]
> {
  return await getJson<MandatoryActivityResult[]>(
    `/${GATEWAY_PATH}/MandatoryActivity`,
  );
}

export type PostMandatoryActivityResponse = ReturnType<
  typeof getMandatoryActivity
>;

export async function postMandatoryActivity(): Promise<void> {
  return await postJson(
    `/${GATEWAY_PATH}/MandatoryActivity/Reminder/DigitalOnboarding`,
    null,
  );
}

export type GetNotificationsParams = { failOnServiceOutage?: boolean };
export type GetNotificationsResponse = ReturnType<typeof getNotifications>;

export async function getNotifications({
  failOnServiceOutage,
}: GetNotificationsParams = {}): Promise<NotificationResult[]> {
  const searchParams = new URLSearchParams();
  if (failOnServiceOutage) searchParams.set("failOnServiceOutage", "true");
  const paramsStr = searchParams.size > 0 ? `?${searchParams.toString()}` : "";
  return await getJson<NotificationResult[]>(
    `/${GATEWAY_PATH}/Notification${paramsStr}`,
  );
}

export type PatchNotificationParams = UpdateNotificationRequest;
export type PatchNotificationResponse = ReturnType<typeof patchNotification>;

export async function patchNotification(
  body: PatchNotificationParams,
): Promise<void> {
  return await patchJson<void>(`/${GATEWAY_PATH}/Notification`, body);
}

export type DeleteNotificationParams = DeleteNotificationRequest;
export type DeleteNotificationResponse = ReturnType<typeof deleteNotification>;

export async function deleteNotification(
  body: DeleteNotificationParams,
): Promise<string> {
  return await remove(`/${GATEWAY_PATH}/Notification`, undefined, body);
}

export const notificationQueryKeys = createQueryKeys("notification", {
  mandatoryActivity: {
    queryKey: null,
    queryFn: getMandatoryActivity,
  },
  notifications: (params?: GetNotificationsParams) => ({
    queryKey: [params],
    queryFn: () => getNotifications(params),
  }),
});

export function useQueryMandatoryActivity() {
  return useQuery(notificationQueryKeys.mandatoryActivity);
}

export function useQueryNotifications(params?: GetNotificationsParams) {
  return useQuery(notificationQueryKeys.notifications(params));
}

export function useMutationPostMandatoryActivity() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postMandatoryActivity,
    onSettled: () => {
      queryClient.invalidateQueries(notificationQueryKeys.mandatoryActivity);
    },
  });
}

export function useMutationPatchNotification() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PatchNotificationParams) => patchNotification(body),
    onSettled: () => {
      queryClient.invalidateQueries(notificationQueryKeys.notifications());
    },
  });
}

export function useMutationDeleteNotification() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: DeleteNotificationParams) => deleteNotification(body),
    onSettled: () => {
      queryClient.invalidateQueries(notificationQueryKeys.notifications());
    },
  });
}
