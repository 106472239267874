import { IS_BROWSER } from "./isBrowser";
import { logTrace, logPrint } from "./logger";
import { TimeSpan } from './TimeSpan'

const timers: Map<string, ActivityTimer<any>> = new Map();

export function getRunningTimers(): void {
  logPrint('Running timers');

  timers.forEach((value, key) => {
    var now = new Date().getTime();
    var startTime = value!.startedTime!.getTime();
    var endTime = startTime + value!.delayTime!.totalMilliseconds;
    var timeLeft = endTime - now;
    var r = TimeSpan.fromMilliseconds(timeLeft);
    logPrint(`${key} ${r.friendly}`);
  });
}

if (IS_BROWSER)
  window['getRunningTimers'] = getRunningTimers;

type ActivityTimerParams = {
  name: string,
  onTrigger: (state: any) => Promise<boolean>
  getDelay: (state: any) => TimeSpan
  failed: (state: any, error?: ActivityTimerFailed) => Promise<void>
  stopped: () => void
}

type SimpleTimer = {
  promise: Promise<unknown>,
  cancel: () => void
}

export type ActivityTimerFailed = {
  rejected: boolean,
  error: any
}

function createSimpleTimer(delay: TimeSpan/* in seconds */): SimpleTimer {
  let timer: NodeJS.Timeout | null = null;
  let reject: ((reason?: any) => void) | null = null;

  const promise = new Promise((resolve, _reject) => {
    reject = _reject;
    timer = setTimeout(resolve, delay.totalMilliseconds);
  });

  return {
    get promise() { return promise; },
    cancel() {
      if (timer) {
        clearTimeout(timer);
        timer = null;
        reject!();
        reject = null;
      }
    }
  };
}

export class ActivityTimer<T> {
  private timer: SimpleTimer | null;
  private activityStopping: boolean = false;
  private params: ActivityTimerParams;
  public id: number;
  public startedTime: Date | null = null;
  public delayTime: TimeSpan | null = null;

  constructor(params: ActivityTimerParams) {
    this.params = params;
    this.id = +new Date;
    logTrace(`${this.id}: Creating ${this.params.name} timer - ${new Date().toJSON()}`);
    this.timer = null;
  }
  
  public start(state: T) {
    logTrace(`${this.id}: Starting ${this.params.name} timer - ${new Date().toJSON()}`);
    let delay = TimeSpan.zero;
    this.startedTime = new Date();

    try {
      delay = this.params.getDelay(state);
      this.delayTime = delay;
      if (delay.totalMilliseconds <= 0) {
        this.onTimerFailure(state, true);
      } else {
        this.timer = createSimpleTimer(delay);
        if(this.activityStopping){this.timer.cancel();}
        else{
          this.timer.promise
            .then(
                ()=> this.onTimerComplete(state),
                () => this.onTimerFailure(state, true)
            )
            .catch((reason: any) => this.onTimerFailure(state, false, reason));
        }
      }
    } catch(e: any) {
      this.onTimerFailure(state, false, e);
    }
  }

  private async onTimerComplete(state: T) {
    if(this.activityStopping){ return; }
    logTrace(`${this.id}: Timer Complete ${this.params.name} - ${new Date().toJSON()}`);
    if (await this.params.onTrigger(state)) {
      const newDelay = await this.params.getDelay(state);
      if (newDelay.totalMilliseconds > -1) {
        this.resetTimer(state);
      }
    }
  }

  private onTimerFailure(state: T, rejected: boolean, error?: any) {

    if(this.activityStopping){ return; }

    logTrace(`${this.id}: Timer Failure ${this.params.name} - ${new Date().toJSON()}`);
    return this.params.failed(state, { rejected, error });
  }

  private async resetTimer(state: T) {
    logTrace(`${this.id}: Resetting ${this.params.name} timer - ${new Date().toJSON()}`);
    if (this.timer) {
      this.timer.cancel();
      this.timer = null;
    }
    this.start(state);
  }

  public async reset(state: T, refresh: boolean) {
    logTrace(`${this.id}: Timer Reset ${this.params.name} - ${new Date().toJSON()}`);
    if (refresh) {
      await this.params.onTrigger(state);
    }

    this.resetTimer(state);
  }

  public kill() {
    logTrace(`${this.id}: Killing ${this.params.name} timer - ${new Date().toJSON()}`);

    if (this.timer) { this.timer.cancel(); }
    else{ this.activityStopping = true; }
    
    this.params.stopped();
  }
}

export function createActivityTimer<T>({ name, onTrigger, getDelay, failed, stopped }: ActivityTimerParams): ActivityTimer<T> {
  if (timers.has(name)) {
    const reusedTimer = timers.get(name)!;
    logTrace(`${reusedTimer.id}: Reusing ${name} timer - ${new Date().toJSON()}`);
    return timers.get(name)!;
  }
 
  var t = new ActivityTimer<T>({ name, onTrigger, getDelay, failed, stopped });

  timers.set(name, t);

  return t;
}

export function getTimer(name: string) {
  if (timers.has(name)) {
    return timers.get(name);
  }
  
  logTrace(`Timer ${name} not found`)
  return null;
}

export function startTimer<T>(name: string, data: T) {
  const timer = getTimer(name);
  if (timer) { 
    timer.start(data);
  }
}

export function resetTimer<T>(name: string, data: T) {
  const timer = getTimer(name);
  if (timer) { 
    timer.reset(data, false);// When is resfresh true
  }
}

export function killTimer(name: string) {
  const timer = getTimer(name);
  if (timer) {
    timer.kill();
  }

  if (timers.has(name)) {
    timers.delete(name);
  }
}

export function killAllTimers() {
  timers.forEach(x => x.kill());
  timers.clear();
}