"use client";

import { forwardRef } from "react";
import {
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectPortal,
  SelectRoot,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  type SelectContentProps,
  type SelectGroupProps,
  type SelectGroupRef,
  type SelectItemProps,
  type SelectItemRef,
  type SelectItemTextProps,
  type SelectLabelProps,
  type SelectRootProps,
  type SelectSeparatorProps,
  type SelectSeparatorRef,
  type SelectTriggerProps,
  type SelectTriggerRef,
  type SelectValueProps,
} from "./select.components";
import { formatAriaInvalid } from "./style.utils";

// -----------------------------------------------------------------------------

export type OptgroupRef = SelectGroupRef;

export interface OptgroupProps extends SelectGroupProps {
  label: SelectLabelProps["children"];
}

const Optgroup = forwardRef<OptgroupRef, OptgroupProps>(
  ({ children, label, ...props }, ref) => {
    return (
      <SelectGroup {...props} ref={ref}>
        <SelectLabel>{label}</SelectLabel>
        {children}
      </SelectGroup>
    );
  },
);

Optgroup.displayName = "Optgroup";

export { Optgroup };

// -----------------------------------------------------------------------------

export type OptionRef = SelectItemRef;

export interface OptionProps
  extends Omit<SelectItemProps, "children">,
    Pick<SelectItemTextProps, "children"> {}

const Option = forwardRef<OptionRef, OptionProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <SelectItem {...props} ref={ref}>
        <SelectItemText>{children}</SelectItemText>
        <SelectItemIndicator />
      </SelectItem>
    );
  },
);

Option.displayName = "Option";

export { Option };

// -----------------------------------------------------------------------------

export type OptionDividerRef = SelectSeparatorRef;

export interface OptionDividerProps extends SelectSeparatorProps {}

export const OptionDivider = SelectSeparator;

// -----------------------------------------------------------------------------

export type SelectRef = SelectTriggerRef;

export interface SelectProps
  extends SelectRootProps,
    Pick<SelectTriggerProps, "aria-invalid" | "className" | "id" | "size">,
    Pick<SelectValueProps, "placeholder">,
    Pick<SelectContentProps, "position"> {
  renderSelectedValue?: (value: SelectRootProps["value"]) => React.ReactNode;
}

const Select = forwardRef<SelectRef, SelectProps>(
  (
    {
      "aria-invalid": ariaInvalid,
      children,
      className,
      disabled,
      id,
      placeholder = "Select",
      position = "popper",
      renderSelectedValue,
      size,
      value,
      ...props
    },
    ref,
  ) => {
    return (
      <SelectRoot {...props} value={value}>
        <SelectTrigger
          id={id}
          disabled={disabled}
          aria-invalid={ariaInvalid}
          size={size}
          className={className}
          ref={ref}
        >
          {!!value && !!renderSelectedValue ? (
            <SelectValue
              placeholder={placeholder ?? undefined}
              aria-label={value}
            >
              {renderSelectedValue(value)}
            </SelectValue>
          ) : (
            <SelectValue placeholder={placeholder ?? undefined} />
          )}
          <SelectIcon
            rootSize={size}
            size={size}
            invalid={formatAriaInvalid(ariaInvalid)}
            disabled={disabled}
          />
        </SelectTrigger>
        <SelectPortal>
          <SelectContent position={position}>
            <SelectScrollUpButton />
            <SelectViewport position={position}>{children}</SelectViewport>
            <SelectScrollDownButton />
          </SelectContent>
        </SelectPortal>
      </SelectRoot>
    );
  },
);

Select.displayName = "Select";

export { Select };
