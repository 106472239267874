import { Segment } from "#app/(unauthorized)/authentication/segment";
import { type NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import filterHelper from "./filterHelper";
import { type NavRoutesConfigFilterParams } from "./filterNav";

export default function preclientNavFilter({
  jwt,
  navRoutesConfig,
  requiredData: _requiredData,
}: NavRoutesConfigFilterParams): NavRoutesConfig {
  if (jwt.segment == Segment.Preclient) {
    const filtering = filterHelper(navRoutesConfig);
    return filtering
      .removeRouteById("Home")
      .removeRouteSubById("AccountServices", "AccountServicesEbill")
      .getFilteredRoutesConfig();
  }

  return navRoutesConfig;
}
