"use client";

import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { usePathname, useRouter } from "next/navigation";

export function PageRedirects({ children }: { children: React.ReactNode }) {
  const jwt = useJwt();
  const activePath = usePathname();
  const router = useRouter();

  if (activePath === "/") {
    if (jwt.segment === Segment.CorporateEntities) {
      router.push("/investing-tools/baird-research");
      return;
    } else if (jwt.segment === Segment.Preclient) {
      router.push("/home");
      return;
    }
  }

  return children;
}
